import { Alert, Loader } from '@ralston-instruments/app.fieldlab.components.web'
import { combineErrorMessages } from '@/components/molecules/CombinedErrorMessages'

export function defaultShouldShowError(queryModel) {
  return queryModel.isError
}
export function defaultShouldShowLoading(queryModel) {
  return queryModel.isLoading || queryModel.isIdle
}

export function getErrorOrLoading({
  queryModel,
  verb = 'Loading',
  shouldShowError = defaultShouldShowError,
  shouldShowLoading = defaultShouldShowLoading,
  renderLoading,
  renderError,
  loaderProps = {},
}) {
  if (shouldShowError(queryModel)) {
    const errorContent = <QueryError queryModel={queryModel} verb={verb} />
    return renderError ? renderError(errorContent) : errorContent
  }

  if (shouldShowLoading(queryModel)) {
    const loadingContent = loaderProps.inline ? (
      <Loader title={`${verb} ${queryModel.name || ''}`} {...loaderProps} />
    ) : (
      <div className="p-12">
        <div className="h-40 relative">
          <Loader title={`${verb} ${queryModel.name || ''}`} {...loaderProps} />
        </div>
      </div>
    )
    return renderLoading ? renderLoading(loadingContent) : loadingContent
  }

  return null
}

export function QueryViews({
  queryViews = [],
  renderLoading,
  renderError,
  render,
  children = null,
  loaderProps,
}) {
  for (let i = 0; i < queryViews.length; i++) {
    const queryView = getErrorOrLoading({
      renderLoading,
      renderError,
      loaderProps,
      ...queryViews[i],
    })
    if (queryView) {
      return queryView
    }
  }

  return render ? render(queryViews) : children
}

export default function QueryView({
  queryModel,
  children = null,
  render,
  ...rest
}) {
  const errorOrLoading = getErrorOrLoading({ queryModel, ...rest })
  if (errorOrLoading) {
    return errorOrLoading
  }
  return render ? render(queryModel) : children
}

export function QueryError({
  queryModel,
  noun = 'data',
  verb = 'Loading',
  title,
  actions,
}) {
  return (
    <Alert componentState="danger">
      <h4>
        {title ||
          `There was an error ${verb.toLowerCase()} this ${
            queryModel.name || noun
          }:`}
      </h4>
      <p>
        <strong>Error from server:</strong> {queryModel.error?.message}
      </p>
      {!!queryModel.error?.data?.errors && (
        <>
          <p>
            <strong>Details:</strong>
          </p>
          <ul>
            {combineErrorMessages(queryModel.error.data.errors).map(
              (error, i) => (
                <li key={i}>{error?.message || error}</li>
              )
            )}
          </ul>
        </>
      )}
      {actions && <div className="text-right">{actions}</div>}
    </Alert>
  )
}
