function getUrl(url, fullyQualified) {
  if (fullyQualified) {
    return process.env.NEXT_PUBLIC_APP_URL + url
  }
  return url
}

const paths = {
  serviceLab: ({ serviceLabId } = {}, fullyQualified = false) =>
    getUrl(`/service-lab/${serviceLabId}`, fullyQualified),

  serviceLabDevices: ({ serviceLabId } = {}, fullyQualified = false) =>
    getUrl(`/service-lab/${serviceLabId}/devices`, fullyQualified),

  serviceLabDevice: ({ serviceLabId, deviceId } = {}, fullyQualified = false) =>
    getUrl(
      `/service-lab/${serviceLabId}/devices/view/${deviceId}`,
      fullyQualified
    ),

  serviceLabDeviceTestReports: (
    { serviceLabId, deviceId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/devices/view/${deviceId}/test-reports`,
      fullyQualified
    ),

  serviceLabDeviceCertificates: (
    { serviceLabId, deviceId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/devices/view/${deviceId}/certs`,
      fullyQualified
    ),

  serviceLabDeviceCertificate: (
    { serviceLabId, deviceId, certId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/devices/view/${deviceId}/certs/${certId}`,
      fullyQualified
    ),

  serviceLabDeviceHistory: (
    { serviceLabId, deviceId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/devices/view/${deviceId}/history`,
      fullyQualified
    ),

  serviceLabDeviceHistoryItem: (
    { serviceLabId, deviceId, historyItemId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/devices/view/${deviceId}/history/${historyItemId}`,
      fullyQualified
    ),

  serviceLabCustomers: ({ serviceLabId } = {}, fullyQualified = false) =>
    getUrl(`/service-lab/${serviceLabId}/customers`, fullyQualified),

  serviceLabCustomer: (
    { serviceLabId, customerId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/customers/view/${customerId}`,
      fullyQualified
    ),

  serviceLabUsers: ({ serviceLabId } = {}, fullyQualified = false) =>
    getUrl(`/service-lab/${serviceLabId}/account/users`, fullyQualified),

  serviceLabUser: ({ serviceLabId, userId } = {}, fullyQualified = false) =>
    getUrl(
      `/service-lab/${serviceLabId}/account/users/view/${userId}`,
      fullyQualified
    ),

  serviceLabRsvp: (
    { serviceLabId, invitationCode } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/rsvp/${invitationCode}`,
      fullyQualified
    ),

  serviceLabTestReports: ({ serviceLabId } = {}, fullyQualified = false) =>
    getUrl(`/service-lab/${serviceLabId}/test-reports`, fullyQualified),

  serviceLabTestReport: (
    { serviceLabId, testReportId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/test-reports/view/${testReportId}`,
      fullyQualified
    ),

  serviceLabCalibrationReports: (
    { serviceLabId } = {},
    fullyQualified = false
  ) => getUrl(`/service-lab/${serviceLabId}/certification`, fullyQualified),

  serviceLabCalibrationReportsApproved: (
    { serviceLabId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/certification/approved`,
      fullyQualified
    ),
  serviceLabCalibrationReportsPending: (
    { serviceLabId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/certification/pending`,
      fullyQualified
    ),

  serviceLabCalibrationReport: (
    { serviceLabId, calibrationReportId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/certification/view/${calibrationReportId}`,
      fullyQualified
    ),

  serviceLabReferenceDevices: ({ serviceLabId } = {}, fullyQualified = false) =>
    getUrl(`/service-lab/${serviceLabId}/references`, fullyQualified),

  serviceLabReferenceDevice: (
    { serviceLabId, referenceId } = {},
    fullyQualified = false
  ) =>
    getUrl(
      `/service-lab/${serviceLabId}/references/view/${referenceId}`,
      fullyQualified
    ),

  adminProduct: ({ productId } = {}, fullyQualified = false) =>
    getUrl(
      `/admin/master-product-admin/products/view/${productId}`,
      fullyQualified
    ),
  adminProducts: (_, fullyQualified = false) =>
    getUrl(`/admin/master-product-admin/products`, fullyQualified),
  adminManufacturer: ({ manufacturerId } = {}, fullyQualified = false) =>
    getUrl(
      `/admin/master-product-admin/manufacturers/view/${manufacturerId}`,
      fullyQualified
    ),
  adminManufacturers: (_, fullyQualified = false) =>
    getUrl(`/admin/master-product-admin/manufacturers`, fullyQualified),
  adminModel: ({ modelId } = {}, fullyQualified = false) =>
    getUrl(
      `/admin/master-product-admin/models/view/${modelId}`,
      fullyQualified
    ),
  adminModels: (_, fullyQualified = false) =>
    getUrl(`/admin/master-product-admin/models`, fullyQualified),
  adminProductType: ({ productTypeId } = {}, fullyQualified = false) =>
    getUrl(
      `/admin/master-product-admin/product-types/view/${productTypeId}`,
      fullyQualified
    ),
  adminProductTypes: (_, fullyQualified = false) =>
    getUrl(`/admin/master-product-admin/product-types`, fullyQualified),

  adminServiceLabs: (_, fullyQualified = false) =>
    getUrl(`/admin/customer-admin/service-labs`, fullyQualified),

  adminServiceLab: ({ serviceLabId } = {}, fullyQualified = false) =>
    getUrl(
      `/admin/customer-admin/service-labs/view/${serviceLabId}`,
      fullyQualified
    ),

  adminUsers: (_, fullyQualified = false) =>
    getUrl(`/admin/customer-admin/users`, fullyQualified),

  adminUser: ({ userId } = {}, fullyQualified = false) =>
    getUrl(`/admin/customer-admin/users/view/${userId}`, fullyQualified),
}

export default paths
